<!--商户列表-->
<template>
  <div class="men-model" v-loading="pageLoad">
    <Tree
      class="menus-tree"
      :data="treeData"
      default-expand-all
      expand-on-click-node
      @node-click="handleNodeClick"
    />

    <div class="tree-info" v-loading="menusInfoLoad">
      <div class="tree-info-head">
        <span class="info-head-title">{{ menusForm.menuName }}</span>
        <template v-if="menusForm.menuId && menusForm.menuId !== 'root'">
          <el-button
            v-if="menusForm.menuId"
            type="warning"
            @click="addMenus('编辑菜单', 1)"
          >
            编辑
          </el-button>
          <el-button type="danger" @click="delMenu(menusForm.menuId)">
            删除
          </el-button>
        </template>

        <el-button type="primary" @click="addMenus('新增菜单', 2)">
          {{ menusForm.menuLevel === 1 ? "添加菜单" : "添加权限" }}
        </el-button>
      </div>

      <el-form
        class="menus-form"
        ref="form"
        :model="menusForm"
        readonly
        size="large"
        label-width="120px"
      >
        <el-form-item label="菜单ID">
          <el-input v-model="menusForm.menuId" readonly placeholder="ID" />
        </el-form-item>
        <el-form-item label="父级ID">
          <el-input
            v-model="menusForm.parentId"
            readonly
            placeholder="parentId"
          />
        </el-form-item>
        <el-form-item label="菜单名称">
          <el-input v-model="menusForm.menuName" readonly placeholder="name" />
        </el-form-item>
        <el-form-item label="菜单图标">
          <el-input v-model="menusForm.menuIcon" readonly placeholder="icon" />
        </el-form-item>
        <el-form-item label="菜单路径">
          <el-input
            v-model="menusForm.menuUrl"
            readonly
            placeholder="component"
          />
        </el-form-item>
        <el-form-item label="菜单链接">
          <el-input v-model="menusForm.authTag" readonly placeholder="path" />
        </el-form-item>
        <el-form-item label="菜单标识">
          <el-input v-model="menusForm.menuTag" readonly placeholder="name" />
        </el-form-item>
        <el-form-item label="排序">
          <el-input
            v-model="menusForm.menuSort"
            readonly
            placeholder="sort"
            oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
          />
        </el-form-item>
        <el-form-item label="启用状态">
          <el-switch
            v-model="menusForm.state"
            disabled
            :active-value="1"
            :inactive-value="2"
          />
        </el-form-item>
      </el-form>
    </div>

    <el-dialog
      :title="menuDialog.title"
      :visible.sync="menuDialog.visible"
      width="800px"
      center
      @close="menuDialog.visible = false"
      @closed="handleClose"
    >
      <el-form
        class="operation-menus-form"
        ref="menuFormRef"
        :model="menuDialogForm"
        size="large"
        label-width="80px"
        inline
      >
        <el-form-item label="菜单ID" prop="menuId">
          <el-input
            v-model="menuDialogForm.menuId"
            readonly
            placeholder="menuID"
          />
        </el-form-item>

        <el-form-item label="父级ID" prop="parentId">
          <el-input
            v-model="menuDialogForm.parentId"
            readonly
            placeholder="parentId"
          />
        </el-form-item>

        <el-form-item label="菜单名称" prop="menuName">
          <el-input v-model="menuDialogForm.menuName" placeholder="title" />
        </el-form-item>

        <el-form-item label="菜单图标" prop="menuIcon">
          <el-input v-model="menuDialogForm.menuIcon" placeholder="icon" />
        </el-form-item>

        <el-form-item label="菜单路径" prop="menuUrl">
          <el-input v-model="menuDialogForm.menuUrl" placeholder="component" />
        </el-form-item>

        <el-form-item label="菜单标识" prop="menuTag">
          <el-input v-model="menuDialogForm.menuTag" placeholder="name" />
        </el-form-item>

        <el-form-item label="菜单链接" prop="authTag">
          <el-input v-model="menuDialogForm.authTag" placeholder="path" />
        </el-form-item>

        <el-form-item label="排序" prop="menuSort">
          <el-input
            v-model="menuDialogForm.menuSort"
            placeholder="sort"
            oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
          />
        </el-form-item>

        <el-form-item label="启用状态" prop="state">
          <el-switch
            v-model="menuDialogForm.state"
            :active-value="1"
            :inactive-value="2"
          ></el-switch>
        </el-form-item>

        <el-form-item label="类型" prop="menuType">
          <el-select v-model="menuDialogForm.menuType" placeholder="请选择">
            <el-option label="菜单" value="m" />
            <el-option label="API" value="mo" />
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="menuDialog.visible = false">取消</el-button>
        <el-button :loading="saveLoad" type="primary" @click="saveForm">
          保存
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getmenus, getMenuInfo, saveMenu, deleteMenu } from "@/api/public";
import { Tree } from "element-ui";

export default {
  name: "OrderList",
  components: {
    Tree,
  },
  data() {
    return {
      treeData: [],
      menusForm: {
        menuId: null, // 菜单ID
        parentId: null, // 父级id
        menuName: null, // title
        menuType: "m", // 菜单类型
        menuTag: null, // name
        menuUrl: null, //
        menuIcon: null, // icon
        authTag: null, // path
        menuSort: null, // 排序
        state: 1,
      },
      menusInfoLoad: false,
      pageLoad: true,
      menuDialog: {
        editType: 1,
        title: "",
        visible: false,
      },
      menuDialogForm: {
        menuId: null, // 菜单ID
        parentId: null, // 父级id
        menuName: null, // title
        menuType: "m", // 菜单类型
        menuTag: null, // name
        menuUrl: null, //
        menuIcon: null, // icon
        authTag: null, // path
        menuSort: null, // 排序
        state: 1,
      },
      saveLoad: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.pageLoad = true;
      getmenus({
        menuType: null,
      })
        .then((res) => {
          this.pageLoad = false;
          const { getMenusResponseList } = res.data;
          const formatTree = [
            {
              label: "root",
              state: true,
              menuId: "root",
              parentId: null,
              value: "",
              menuLevel: 1,
              children: [],
            },
          ];
          formatTree[0].children = getMenusResponseList
            .filter((v) => v.parentId === 0)
            .map((v) => {
              return {
                label: v.menuName,
                state: v.state,
                menuId: v.menuId,
                parentId: v.parentId,
                value: "",
                menuLevel: v.menuLevel,
                children: this.formatTree(v.menuId, getMenusResponseList),
              };
            });
          this.treeData = formatTree;
        })
        .catch(() => {
          this.pageLoad = false;
        });
    },
    handleNodeClick(node) {
      if (node.menuId === "root") {
        this.menusForm = {
          menuName: "root",
          menuLevel: 1,
        };
      } else {
        this.menusInfoLoad = true;
        getMenuInfo({
          menuId: node.menuId,
        })
          .then((res) => {
            this.menusInfoLoad = false;
            this.menusForm = res.data;
          })
          .catch(() => {
            this.menusInfoLoad = false;
          });
      }
    },
    addMenus(title, editType) {
      this.menuDialog = {
        title: title,
        editType: this.menusForm.menuId === "root" ? 3 : editType,
        visible: true,
      };
      this.$nextTick(() => {
        if (editType === 1) {
          this.menuDialogForm = JSON.parse(JSON.stringify(this.menusForm));
        } else {
          this.menuDialogForm.parentId = this.menusForm.menuId;
          this.menuDialogForm.menuId = null;
          this.menuDialogForm.menuType =
            this.menusForm.menuLevel === 2 ? "mo" : "m";
        }
      });
    },
    handleClose() {
      this.menuDialog.title = "";
      this.menuDialog.editType = 1;
      this.saveLoad = false;
      this.$refs.menuFormRef.resetFields();
    },
    saveForm() {
      this.saveLoad = true;
      saveMenu(this.menuDialogForm)
        .then(() => {
          this.$message.success("保存成功");
          this.menuDialog.visible = false;
          this.init();
        })
        .catch(() => {
          this.saveLoad = false;
        });
    },
    delMenu() {
      this.$messageBox
        .confirm("删除后，数据不可恢复。是否确认删除？", "确认删除", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "error",
        })
        .then(() => {
          deleteMenu({
            menuId: this.menusForm.menuId,
          }).then(() => {
            this.$message.success("删除成功");
            this.menuDialog.visible = false;
            this.init();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    formatTree(id, array) {
      const childs = [];
      for (const arr of array) {
        if (arr.parentId === id) {
          childs.push({
            label: arr.menuName,
            state: arr.state,
            menuId: arr.menuId,
            parentId: arr.parentId,
            value: "",
            menuLevel: arr.menuLevel,
          });
        }
      }
      for (const child of childs) {
        const childscopy = this.formatTree(child.menuId, array);
        if (childscopy.length) {
          child.children = childscopy;
        }
      }
      return childs;
    },
  },
};
</script>

<style lang="scss">
.men-model {
  width: 100%;
  height: 100%;
  padding: 16px;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  .menus-tree {
    flex: 1;
    height: 100%;
    overflow-y: auto;
    .el-tree-node__content {
      height: 40px;
    }
  }
  .tree-info {
    flex: 1.2;
    height: 100%;
    overflow-y: auto;
    margin-left: 16px;
    background: #fff;
    box-sizing: border-box;
    .tree-info-head {
      padding: 4px;
      border-bottom: 1px #f2f2f2 solid;
      text-align: right;
      position: relative;
      .info-head-title {
        display: inline-block;
        position: absolute;
        left: 12px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 14px;
        font-weight: 500;
        margin-right: auto;
      }
    }
    .menus-form {
      padding-right: 24px;
      max-width: 600px;
      margin-top: 24px;
    }
  }
}
.operation-menus-form {
  .el-form-item {
    width: 48%;
    .el-form-item__content {
      width: calc(100% - 80px);
      .el-select {
        width: 100%;
      }
    }
  }
}
</style>
